// import React, { useRef, useState } from "react"
// import BackgroundImage from "gatsby-background-image"
// import styled from "@emotion/styled"
// import { css } from "@emotion/react"
// import axios from "axios"
// import Layout from "../components/layout"
// import { GlobalStyles, mediaBreakpoints } from "../styles/styles"
// import { newGlobalStyles, NewMediaBreakpoints } from "../styles/newstyles"
// import useStyles from "../materialStyles/customStyle"
// import GoogleMap from "../components/googleMap"
// import useLocations from "../hooks/use-locations"
// import useEmailContact from "../hooks/use-emailContacts"
// import useContacts from "../hooks/use-contact"
// import useStartingPointers from "../hooks/use-startingPoints"
// import { useTrackVisibilityObserver } from "./../hooks/use-trackVisibilityObserver"
// import SEO from "../components/SEO"
// import Check from "../assets/images/check.svg"
// import { mapStyles } from "../content/contactContent"
// import { Link } from "gatsby"
// import validator from 'validator';
// const { small, smallAndMedium, medium, xLarge, ipad } = NewMediaBreakpoints

// const ImageWrapper = styled("div")`
//   height: 84.3rem;
//   width: 100%;
//   overflow: hidden;
//   @media ${small} {
//     height: auto;
//   }
//   @media only all and (max-width: 405px) {
//     height: auto;
//   }
// `

// const ImageBackground = styled(BackgroundImage)`
//   background-size: cover;
//   height: 100%;
//   width: 100%;
// `
// const EmailContainer = styled("div")`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   opacity: 1;
//   background-color: rgb(3, 3, 3,0.9);
//   padding: 4.5rem;
//   @media ${smallAndMedium} {
//     padding: 2.5rem;
//   }
//   @media ${small} {
//     flex-direction: column;
//     padding: 3.75rem 5%;
//   }
// `

// const CheckBoxText = styled("div")`
//   opacity: 0.8;
//   font-family: Mulish;
//   font-size: 1rem;
//   text-align: left;
//   color: #ffffff;
//   line-height:1;
// `
// const EmailRightContainer = styled("div")`
//   width: 35.43rem;
//   margin-left: 1rem;
//   @media ${medium} {
//     width: 25rem;
//   }
//   @media ${small} {
//     margin-left: 0;
//     width: 100%;
//   }
// `
// const TextAreaContainer = styled("textarea")`
//   width: 100%;
//   height: 14.625rem;
//   border: solid 1px #363636;
//   color: #fff;
//   background-color: #030303;
//   border-redius: 2px;
//   resize: none;
//   padding: 1.5rem 1.7rem;
//   font-family: Mulish;
//   font-size: 16px;
//   text-align: left;
//   margin-top:1.2rem;
//   &::placeholder {
//     color: #c4c4c4;
//   }
// `
// const EmailAreaContainer = styled("input")`
//   width: 100%;
//   border: solid 1px #363636;
//   color: #fff;
//   background-color: #030303;
//   border-redius: 2px;
//   resize: none;
//   padding: 1.5rem 1.7rem;
//   font-family: Mulish;
//   font-size: 16px;
//   text-align: left;
//   &::placeholder {
//     color: #c4c4c4;
//   }
// `

// const SubmitButton = styled("button")`
//   width: 100%;
//   padding: 1.25rem;
//   text-align: center;
//   font-family: Mulish;
//   font-size: 12px;
//   font-weight: 800;
//   line-height: 1.17;
//   letter-spacing: 1.2px;
//   color: #ffffff;
//   background-color: #737272;
//   border:0px;
//   margin-top: 1rem;
//   &:focus{
//     background-color:#5451ff;
//   }
// `
// const OfficeContainer = styled("div")`
//   width: 100%;
//   background-color: rgb(1.2, 1.2, 1.2, 0.5);
//   height: 12.5rem;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 4.25rem;
// `
// const OfficeText = styled("h2")`
//   color: #ffffff;
//   @media ${small} {
//     font-size: 40px;
//   }
// `

// const CityWrapper = styled("div")`
//   width: 100%;
//   display: flex;
//   @media ${small} {
//     flex-direction: column;
//   }
// `
// const LocationWrapper = styled("div")`
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   @media ${small} {
//     width: 100%;
//   }
// `
// const CityImageBackground = styled(BackgroundImage)`
//   width: 100%;
//   height: 31.93rem;
// `
// const AddressWrapper = styled("div")`
//   width: 100%;
//   background-color: #fff;
//   padding: 3.125rem 0;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   @media ${small} {
//     padding: 1.5rem 0rem;
//   }
//   @media ${ipad} {
//     padding: 3rem 1.5rem;
//   }
// `

// const AddressHeader = styled("h2")`
//   line-height: 0.89;
//   letter-spacing: 1.2px;
//   text-align: center;
//   color: #000000;
//   margin-bottom: 0.75rem;
//   @media ${mediaBreakpoints.small} {
//    font-size:32px;
//   }
// `
// const AddressContent = styled("div")`
//   font-family: Mulish;
//   font-size: 1rem;
//   line-height: 1.63;
//   text-align: center;
//   color: #000000;
// `

// const ErrorValidationText=styled("div")`
// font-family: Mulish;
// font-size: 0.8rem;
// line-height: 1.63;
// margin-top:0.2rem;
// color: #FE552F;
// `

// const EmailLeftContainer = styled("div")`
//   position: relative;
//   left: 0;
//   top: 0;
//   z-index: 1;
// `
// const EmailWrapper = styled("div")`
//   font-family: Mulish;
//   animation-delay: 1.3s;
//   @media ${mediaBreakpoints.small} {
//     margin-bottom: 2rem;
//   }
//   li {
//     display: flex;
//     justify-content: flex-start;
//     padding: 0.2rem;
//     height: 3.125rem;
//     span {
//       padding: 0.2rem;
//       line-height: 3.125rem;
//     }
//     &:hover {
//       cursor: pointer;
//       color: #fff;
     
//       @media ${small} {
//         align-items: flex-start;
//       }
//     }
//   }
// `
// const ContactListItem = styled("li")`
//   opacity: ${props => (props.selected ? "1" : "0.4")};
//   span:first-of-type {
//     color: white;
//     width: 1.25rem;
//     height: 1.25rem;
//     ${props =>
//       props.selected
     
//         ? `background: url(${Check}) no-repeat center / 100%;`
//         : `border: 0.15rem solid #fff;border-radius: 50%;`}
//     display: inline-block;
//     margin-right: 1.1875rem;
      
//   }
//   &:hover {
//     border:none;
//     opacity: 1;
//   }
//   @media ${small} {
//     font-size: 1.125rem;
//   }
// `

// const MapWrapper = styled.div`
//   width: 100%;
//   position: relative;
//   z-index: 0;
//   @media ${small} {
   
//   }
// `

// const MapTile = styled("div")`
//   width:50%
//   background-color: mintcream;
//   height: 30em;
//   flex: 1;
//   text-align: center;
//   animation-delay: ${props => props.count * 100 + "ms"};
//   &:nth-of-type(odd) {
//     margin-left: 0;
//   }
//   &:nth-of-type(even) {
//     margin-right: 0;
//   }
//   @media ${medium} {
//     height: 20rem;
//     margin-bottom: 3rem;
//     margin-left: 0;
//     margin-right: 0;
//     &:last-of-type {
//       margin-bottom: 0;
//     }
//   }
//   @media ${small} {
//     height: 15rem;
//   }
// `

// const disbaledButton = css`
//   cursor: pointer;
//   opacity: 1;
//   pointer-events: none;
// `

// const enabledButton = css`
//   cursor: pointer;
//   background-color:#5451FF;
//   opacity: 1;
// `

// const SuccessWrapper = styled.div`
//   width: 100%;
//   padding: 3rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `
// const SuccessTile = styled.h3`
//   color: #999999;=
//   padding-bottom:1rem;
// `

// const SuccessText = styled.h5`
//   color: #999999;
//   padding-bottom: 1rem;
// `

// const mapClick = (map, mapEvent) => {
//   window.open(
//     `https://www.google.com.sa/maps/search/Piktorlabs/@${map.Coordinates},17z`,
//     "_blank"
//   )
// }

// const Contacts = props => {
//   const [message, setMessage] = useState("")
//   const [email, setEmail] = useState("")
//   const [success, setSuccess] = useState(false)
//   const [error, setError] = useState(false)
//   const [emailValidationState,setEmailValidationState]=useState(false)
//   const locations = useLocations()
//   const contactData = useContacts()
//   const emailContact = useEmailContact()
//   const contactRef = useRef(null)
//   const successRef = useRef(null)
//   const classes = useStyles()
//   const startingPointers = useStartingPointers()
//   const contactEmail = emailContact.filter(item => item.purpose === "Hello")
//   const mapSectionRef = useRef(null)
//   const [selectedStarter, setSelectedStarter] = useState(0) // index of conversationStarters
//   // TODO: Add Conversation starters to Wordpress

//   const [inView] = useTrackVisibilityObserver(mapSectionRef, {
//     threshold: 0.5,
//   })

//   const [inView1] = useTrackVisibilityObserver(contactRef, {
//     threshold: 0.5,
//   })

//   const defaultProps = {
//     zoom: 17,
//     MapOptions: {
//       disableDefaultUI: true,
//       styles: mapStyles,
//     },
//   }
//   const emailer = () => {
//     try {
//       axios
//         .post(`/api/emailCommunication`, {
//           message: message,
//           subject: startingPointers[selectedStarter].pointers,
//           from: email,
//           to: contactEmail[0].emailAddress,
//         })
//         .then(res => {
//           if (res.data.success) {
//             setSuccess(true)
//             setEmail("")
//             setMessage("")
//             setEmailValidationState(false)
//           }
//         })
//         .catch(err => {
//           setError(true)
//           setEmail("")
//           setMessage("")
//           setEmailValidationState(false)
//         })
//     } catch (err) {
//       setError(true)
//       setEmail("")
//       setMessage("")
//       setEmailValidationState(false)
//     }
//   }

//   const validateEmail=(e)=>{
//   const emailFieldValue=e.target.value;
//   if(emailFieldValue===""){
//     return setEmailValidationState(false)
//   }

//    if(validator.isEmail(e.target.value)){
//      return setEmailValidationState(false)
//    };
  
//    return setEmailValidationState(true)

//   }

//   return (
//     <Layout theme="dark" duration="1s" pathname="/contact">
//       <SEO
//         title="Piktorlabs | Contact Us"
//         description="Contact piktorlabs"
//         pathname="/contact"
//         article={false}
//       />
//       <newGlobalStyles.StyledSection>
//         <ImageWrapper>
//           <ImageBackground
//             imgStyle={{
//               width: "100%",
//               height: "100%",
//               backgroundSize: "none",
//             }}
//             fluid={contactData.hero_image}
//           >
//             <newGlobalStyles.HeroImageSection>
//               <h1
//                 css={css`
//                   color: #fff;
//                   @media ${mediaBreakpoints.small} {
//                     text-align: center;
//                     width: 80%;
//                     font-size:40px;
//                   }
//                 `}
//               >
//                 {contactData.main_title || "Let's connect!"}
//               </h1>
//             </newGlobalStyles.HeroImageSection>
//             <newGlobalStyles.DescriptionSection
//               css={css`
//                 @media ${mediaBreakpoints.small} {
//                   max-width: 100%;
//                   width:100%;
//                 }
//               `}
//             >
//               <EmailContainer>
//                 <EmailLeftContainer>
//                   <h3
//                     css={css`
//                       font-weight: 600;
//                       color: #fff;
//                       margin-bottom:1.8125rem;
//                       font-family:IvyMode-Regular;
//                       @media ${small} {
//                         text-align:center:
//                         font-size:30px;
//                       }
//                     `}
//                     inView={inView1}
//                     count={5}
//                   >
//                     Start a conversation
//                   </h3>
//                   <EmailWrapper inView={inView1} count={9}>
//                     <ul>
//                       {startingPointers &&
//                         startingPointers.length > 0 &&
//                         startingPointers.map((item, index) => {
//                           return (
//                             <ContactListItem
//                               id="contact-item-1"
//                               selected={Boolean(selectedStarter === index)}
//                               onClick={() => setSelectedStarter(index)}
//                             >
//                               <span></span>
//                               <CheckBoxText
//                                 css={
//                                   selectedStarter === index
//                                     ? css`
//                                         opacity: 1;
//                                       `
//                                     : css``
//                                 }
//                               >
//                                 {item.pointers}
//                               </CheckBoxText>
//                             </ContactListItem>
//                           )
//                         })}
//                     </ul>
//                   </EmailWrapper>
//                 </EmailLeftContainer>
//                 {!success && !error && (
//                   <EmailRightContainer>
//                     <EmailAreaContainer
//                     css={
//                       emailValidationState? css`
//                       border-color:#FE552F;
//                     `
//                   : css``
//                     }
//                       onChange={e => setEmail(e.target.value)}
//                       placeholder="Email..."
//                       onBlur={(e) => {
//                         validateEmail(e);
//                       }}
//                     ></EmailAreaContainer>
//                     {
//                       emailValidationState && (<ErrorValidationText>
//                        Please Enter a valid Email Id.
//                       </ErrorValidationText>)
//                     }
//                     <TextAreaContainer
//                       onChange={e => setMessage(e.target.value)}
//                       placeholder="Message..."
//                     ></TextAreaContainer>
//                     <SubmitButton
//                       css={
//                         message === "" || email === "" || emailValidationState
//                           ? disbaledButton
//                           : enabledButton
//                       }
//                       onClick={() => emailer()}
//                     >
//                       SUBMIT
//                     </SubmitButton>
//                   </EmailRightContainer>
//                 )}
//                 {(success || error) && (
//                   <EmailRightContainer>
//                     <SuccessWrapper>
//                       <SuccessTile>
//                         {success
//                           ? "Thanks for your message!"
//                           : "Something went wrong!"}
//                       </SuccessTile>
//                       <SuccessText>
//                         {success ? "We’ll be in touch soon" : "Please retry."}
//                       </SuccessText>
//                       {success && (
//                         <Link to="/">
//                           <newGlobalStyles.submitButton
//                            activeBgColor="#8381ff"
//                             css={css`
//                               width: fit-content;
//                             `}
//                           >
//                             {"Done"}
//                           </newGlobalStyles.submitButton>
//                         </Link>
//                       )}
//                       {error && (
//                         <newGlobalStyles.submitButton
//                         activeBgColor="#8381ff"
//                           onClick={() => setError(false)}
//                           css={css`
//                             width: fit-content;
//                           `}
//                         >
//                           {"Retry"}
//                         </newGlobalStyles.submitButton>
//                       )}
//                     </SuccessWrapper>
//                   </EmailRightContainer>
//                 )}
//               </EmailContainer>
//             </newGlobalStyles.DescriptionSection>
//             <OfficeContainer>
//               <OfficeText> Our offices</OfficeText>
//             </OfficeContainer>
//           </ImageBackground>
//         </ImageWrapper>
//       </newGlobalStyles.StyledSection>
//       <newGlobalStyles.StyledSection>
//         <CityWrapper>
//           <LocationWrapper>
//             <CityImageBackground fluid={contactData.office_image_seattle} />
//             <AddressWrapper>
//               <AddressHeader>{locations[0].LocationTitle}</AddressHeader>
//               <AddressContent>{locations[0].OfficeAddress}</AddressContent>
//              </AddressWrapper>
//              {locations && locations[0]?.LocationTitle && 
//               <MapWrapper
//                css={css`
//               display:none;
//               @media ${mediaBreakpoints.small} {
//                display:block;
//               }
//              `}>
//               <MapTile               
//                 className="animation-tile" count={1} inView={inView}>
//                   <GoogleMap
//                     defaultProps={{
//                       ...defaultProps,
//                       center:{
//                         lat: parseFloat(locations[0]?.Coordinates.split(",")[0]),
//                         lng: parseFloat(locations[0]?.Coordinates.split(",")[1]),
//                       } ,
//                       markerText: locations[0],
//                       onClick: mapEvent => mapClick(locations[0], mapEvent),
//                     }}
//                   />
//                 </MapTile>
//                </MapWrapper>}
//           </LocationWrapper>
//           <LocationWrapper>
//             <CityImageBackground fluid={contactData.office_image_bangalore} />
//             <AddressWrapper>
//               <AddressHeader>{locations[1].LocationTitle}</AddressHeader>
//               <AddressContent>{locations[1].OfficeAddress}</AddressContent>
//              </AddressWrapper>
//              {locations && locations[1]?.LocationTitle  &&
//               <MapWrapper
//                css={css`
//               display:none;
//               @media ${mediaBreakpoints.small} {
//                display:block;
//               }
//              `}>
//               <MapTile               
//                 className="animation-tile" count={0} inView={inView}>
//                   <GoogleMap
//                     defaultProps={{
//                       ...defaultProps,
//                       center:{
//                         lat: parseFloat(locations[1]?.Coordinates.split(",")[0]),
//                         lng: parseFloat(locations[1]?.Coordinates.split(",")[1]),
//                       } ,
//                       markerText: locations[1],
//                       onClick: mapEvent => mapClick(locations[1], mapEvent),
//                     }}
//                   />
//                 </MapTile>
//                </MapWrapper>}
//           </LocationWrapper>
//         </CityWrapper>
//       </newGlobalStyles.StyledSection>
//       <newGlobalStyles.StyledSection>
//         <MapWrapper
//         css={css`
//         display:flex;
//         @media ${mediaBreakpoints.small} {
//          display:none;
//         }
//       `}
//         >
//           {locations.length > 0 &&
//             locations.map((location, i) => {
//               let coordinate = {
//                 lat: parseFloat(location.Coordinates.split(",")[0]),
//                 lng: parseFloat(location.Coordinates.split(",")[1]),
//               }
//               return (
//                 <MapTile className="animation-tile" count={i} inView={inView}>
//                   <GoogleMap
//                     defaultProps={{
//                       ...defaultProps,
//                       center: coordinate,
//                       markerText: location,
//                       onClick: mapEvent => mapClick(location, mapEvent),
//                     }}
//                   />
//                 </MapTile>
//               )
//             })}
//         </MapWrapper>
//       </newGlobalStyles.StyledSection>
//     </Layout>
//   )
// }
// export default Contacts

import React, { useEffect, useState } from "react"
import "../index.css"
import Ustlogo from "../assets/logos/UST-evolve-logo.svg"
import PiktorLogo from "../assets/logos/piktorlabs-logo-updated.svg"

function Contacts() {
  const [counter, updateCounter] = useState(4)
  const [coverClass, updateCoverClass] = useState("cover")
  const [middleDivClass, updateMiddleDivClass] = useState("middle-div")
  useEffect(() => {
    if (counter >= 0) {
      setTimeout(() => {
        if (counter > 0) updateCounter(counter - 1)
        if (counter === 4) {
          updateCoverClass("cover fadeOut-four")
        }
        // if (counter === 4) {
        //   updateCoverClass("cover fadeOut-three")
        // } else if (counter === 3) {
        //   // updateCoverClass("cover fadeOut-two")
        // } else if (counter === 2) {
        //   updateCoverClass("cover fadeOut-one")
        // } else
        else if (counter === 1) {
          // updateCoverClass("cover fadeOut-zero")
          updateMiddleDivClass("middle-div middle-div-to-center")
        } else if (counter === 0) {
          window.location.replace("https://www.ust.com/en/evolve")
        }
      }, 2000)
    }
  })

  return (
    <div className="App">
      <div className={coverClass}></div>
      <div className="logo">
        <img src={Ustlogo} />
      </div>

      <div className={middleDivClass}>
        <div className="vertical-line">
          <img src={PiktorLogo} />
        </div>
        <div className="right-div">
          <div className="right-div-header">
            Farewell, Piktorlabs! Now part of UST Evolve.
          </div>
          <div className="right-div-middle">
            <p>{counter}</p>
          </div>
          <div className="right-div-footer">
            click <a href="https://www.ust.com/en/evolve">here</a> to visit the
            UST home page.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts